import $ from 'jquery'
import LogRocket from 'logrocket'

(->
  class LogRocketSession
    init: ->
      $(document).ready ->
        LogRocket.init('9qetzn/datacrest');

    set_identity: (current_employee) ->
      LogRocket.identify(current_employee.email, {
        name: "#{current_employee.first_name} #{current_employee.last_name}",
        email: current_employee.email,
        appName: 'AppEase'
      });

    clear_identity: ->
      $('.logout-btn').click (event) ->
        LogRocket.identify(null)
        LogRocket.shutdown()

      return

  window.log_rocket_session = new LogRocketSession()
).call this
