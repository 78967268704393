import { Controller } from "@hotwired/stimulus";
import AesAuthentication from "../../../../../services/aes_authentication";
export default class extends Controller {
  static targets = ['id', 'email', 'firstName', 'lastName']

  connect() {
    this.listenForInputFieldHighlight();
  }

  async populateFields() {
    const controller = this;
    this.clearClientFields(controller);

    const _email = this.emailTarget.value;
    const url = new URL(window.location.origin + this.emailTarget.dataset.url);
    url.searchParams.append('email', _email);

    const aesAuthentication = new AesAuthentication()

    loader.show();

    const aesResponse = await aesAuthentication.authenticate({ key: `client-auth-token-${_email}`, values: { client_email: `${_email}` }});
    url.searchParams.append('encrypted_data', aesResponse.encryptedData);
    url.searchParams.append('iv', aesResponse.iv);

    const response = await fetch(url, {
      headers: {
        'X-Rquested-With': 'XMLHttpRequest',
        'Accept': 'application/json'
      }
    });

    if (response.ok){
      const client = await response.json();
      if (client.id != null)
      {
        this.setClientFields(client)
        this.disableFields()
      } else {
        this.disableFields(false);
      }
    }

    loader.hide();
  }

  setClientFields(client = {}) {
    this.firstNameTarget.value = client.first_name;
    this.lastNameTarget.value = client.last_name;
    // this.companyNameTarget.value = client.company_name;
    this.triggerFirstNameLastNameInputEvents();
  }

  clearClientFields(controller) {
    controller.firstNameTarget.value = '';
    controller.lastNameTarget.value = '';
    // controller.companyNameTarget.value = '';
  }

  hideElement(element){
    this.removeClassFromElement(element, 'show');
    this.addClassToElement(element, 'hide');
  }

  showElement(element){
    this.removeClassFromElement(element, 'hide');
    this.addClassToElement(element, 'show');
  }

  removeClassFromElement(element, className){
    element.classList.remove(className)
  }

  addClassToElement(element, className){
    element.classList.add(className)
  }

  triggerInputEvent(element){
    const inputEvent = new Event('input', { bubbles: true });
    element.dispatchEvent(inputEvent);
  }

  triggerFirstNameLastNameInputEvents(){
    this.triggerInputEvent(this.firstNameTarget);
    this.triggerInputEvent(this.lastNameTarget);
  }

  listenForInputFieldHighlight(){
    const inputFields = document.querySelectorAll('.application-link-submission-client-input-field');

    inputFields.forEach(inputField => {
      if (inputField.classList.contains('form__error__flag')){
        inputField.addEventListener('input', () => {
          if (inputField.value.trim() === '' ) {
            inputField.classList.add('form__error');
          } else {
            inputField.classList.remove('form__error');
          }
        });

        inputField.addEventListener('blur', () => {
          if (inputField.value.trim() === '') {
            inputField.classList.add('form__error');
          }
        });
      }
    });
  }

  disableFields(value=true){
    this.firstNameTarget.disabled = value;
    this.lastNameTarget.disabled = value;
    // this.companyNameTarget.disabled = true;
  }
}
