import { Controller } from "@hotwired/stimulus";
import { parse_json_to_string } from "../../../../../../dashboard/javascript/utils";
export default class extends Controller {
  static targets = ["navItem"];

  static values = {
    newDashboardSubmissionGroupCollaboratorsPath: String,
    newDashboardSubmissionGroupDocumentPath: String,
    newDashboardSubmissionGroupDocumentPath: String,
    fetchAgentsDashboardSubmissionsGroupPath: String,
    updateDashboardSubmissionGroupPath: String,
    fetchApplicationsDashboardSubmissionGroupPath: String,
    syncSubmissionGroupsDashboardSubmissionGroupPath: String,
    fetchStatusActionsPath: String,
    newDashboardSubmissionGroupScheduleTemplatePath: String
  };

  async connect() {
    const showDialog = new URLSearchParams(window.location.search).get(
      "show_dialog"
    );

    if (showDialog && this.fetchStatusActionsPathValue) {
      const response = await fetch(this.fetchStatusActionsPathValue, {
        method: "GET",
      });

      this.fetchStatusActions(response);
    }
  }

  handleCardDivForShow(event) {
    window.location.href = event.currentTarget.dataset.submissionGroupShowUrl;
  }

  handleCustomSearchFilterField(event) {
    $(event.target.form).trigger("submit.rails");
  }

  handleResetFilters() {
    this.removeFilterFields();

    $(event.target.form).trigger("submit.rails");
  }

  removeFilterFields() {
    // Refresh Date fields
    selectpicker.clearDatepickerFields(".apps-filter");
    // Refresh Select 2
    selectpicker.refresh_select(".select-picker");
    // Custom Search Field
    document.getElementById("q_searchable_fields_cont").value = "";
  }

  async renderCollaboratorsModal(event) {
    const response = await this.sendRequest(
      this.newDashboardSubmissionGroupCollaboratorsPathValue
    );

    if (response.ok) {
      const { html } = await response.json();
      const target = document.querySelector("#modal_popup");
      this.placeHTMLResponse(html, target);

      selectpicker.init();
      this.toggleModal("addCollaboratorModal");
    }
  }

  placeHTMLResponse(html, containerDiv) {
    containerDiv.innerHTML = html;
  }

  toggleModal(id, hide = false) {
    if (hide) {
      window.$(`#${id}`).modal("hide");
    } else {
      window.$(`#${id}`).modal();
    }
  }

  async sendRequest(url, method = "GET", formData = null) {
    loader.show();

    const csrf_token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    let payload = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        "X-CSRF-Token": csrf_token,
      },
    };

    if (method !== "GET") {
      payload["method"] = method;

      if (formData) {
        payload["body"] = formData;
      }
    }

    const response = await fetch(url, payload);

    loader.hide();

    return response;
  }

  async fetchStatusActions(response) {
    if (response.ok) {
      const { html } = await response.json();

      const target = document.querySelector("#modal_popup");
      this.placeHTMLResponse(html, target);
      window.input_mask.init();
      dashboard.init();
      this.toggleModal("submissionGroupNotesModal");
    }
  }

  async getSubmissionStatusActions(event) {
    const response = await this.sendRequest(
      event.target.dataset.fetchActionsPathValue,
      "GET"
    );

    this.fetchStatusActions(response);
  }

  async updateSubmissionGroupStatus(event) {
    event.preventDefault();
    const url = event.target.action;
    const formData = new FormData(event.target);
    this.sendSubmissionGroupStatusUpdateRequest(url, formData);
  }

  async sendSubmissionGroupStatusUpdateRequest(url, formData) {
    const alertsTarget = document.getElementById("submission-group-alerts");
    alertsTarget.classList.add("d-none");
    const submissionGroupStatusErrorsTarget = document.getElementById(
      "submissionGroupStatusErrors"
    );

    if (submissionGroupStatusErrorsTarget)
      submissionGroupStatusErrorsTarget.classList.add("d-none");

    const response = await this.sendRequest(url, "PATCH", formData);

    if (response.ok) {
      const { html, status_name: statusName, message } = await response.json();

      this.toggleModal("submissionGroupNotesModal", true);
      const target = document.querySelector("#submission-tab");
      datetimepicker.set_picker();
      this.placeHTMLResponse(html, target);
      alertsTarget.classList.remove("d-none");
      alertsTarget.classList.add("alert-success", "p-4");
      this.placeHTMLResponse(message, alertsTarget);
      window.input_mask.init();
      dashboard.init();
      scrollTo(0, 0);
    } else {
      const { message } = await response.json();

      if (submissionGroupStatusErrorsTarget) {
        submissionGroupStatusErrorsTarget.classList.remove("d-none");
        submissionGroupStatusErrorsTarget.innerHTML = message;
      }
    }

    const submissionGroupNotesModalSubmitButton = document.getElementById(
      "submissionGroupNotesModalSubmitButton"
    );

    if (submissionGroupNotesModalSubmitButton)
      submissionGroupNotesModalSubmitButton.disabled = false;

    TimestampsConverter.init();
  }

  async updateSubmissionGroup(event) {
    const alertsTarget = document.getElementById("submission-group-alerts");
    alertsTarget.classList.add("d-none");

    if (event.target.classList.contains("datepicker"))
      datetimepicker.set_hidden_date_value(event.target);

    const form = document.getElementById("submissionGroupForm");
    const formData = new FormData(form);
    const response = await this.sendRequest(form.action, "PATCH", formData);
    if (response.ok) {
      const { html, message } = await response.json();
      const target = document.querySelector("#submission-tab");
      this.placeHTMLResponse(html, target);

      alertsTarget.classList.remove("d-none");
      alertsTarget.classList.add("alert-success", "p-4");
      this.placeHTMLResponse(message, alertsTarget);

      document.querySelectorAll(`button[name='sidebar-option']`).forEach(button => {
        if (button.value !== 'quote_summary') {
          button.classList.remove('active-button');
        } else {
          button.classList.add('active-button');
        }
      });

      TimestampsConverter.init();
      datetimepicker.set_picker();
      window.input_mask.init();
      dashboard.init();
    } else {
      const { message } = await response.json();
      alertsTarget.classList.remove("d-none");
      alertsTarget.classList.add("alert-danger", "p-4");
      this.placeHTMLResponse(message, alertsTarget);
    }
  }

  async closeModal() {
    const target = document.querySelector("#submissionGroupNotesModal");
    target.classList.remove("show");
  }

  async renderSendToEntityModal(event) {
    const response = await this.sendRequest(
      this.fetchAgentsDashboardSubmissionsGroupPathValue
    );

    if (response.ok) {
      const { html } = await response.json();
      const target = document.querySelector("#modal_popup");
      this.placeHTMLResponse(html, target);

      this.toggleModal("sendToEntityModal");
      selectpicker.init();
    }
  }

  async addCollaboratorSubmitForm(event) {
    this.toggleModal("addCollaboratorModal", true);

    event.preventDefault();
    const form = event.target;
    const url = form.action;
    const formData = new FormData(form);
    const response = await this.sendRequest(url, "POST", formData);
    const alertsTarget = document.getElementById("submission-group-alerts");
    alertsTarget.classList.remove("alert-success", "alert-danger", "p-4");
    alertsTarget.innerHTML = "";
    const collaborationDivTarget = document.getElementById("submission-tab");

    const { html } = await response.json();

    if (response.ok) {
      this.placeHTMLResponse(html, collaborationDivTarget);
      this.placeHTMLResponse(
        "Collaboration invite sent successfully!",
        alertsTarget
      );
      alertsTarget.classList.add("alert-success", "p-4");
    } else {
      alertsTarget.classList.add("alert-danger", "p-4");
      this.placeHTMLResponse(html, alertsTarget);
    }
  }

  async sendToEntitySubmitForm(event) {
    this.toggleModal("sendToEntityModal", true);

    event.preventDefault();
    const form = event.target;
    const url = form.dataset.url;
    const formData = new FormData(form);
    const response = await this.sendRequest(url, "POST", formData);
    const alertsTarget = document.getElementById("submission-group-alerts");
    alertsTarget.classList.remove("alert-success", "alert-danger", "p-4");

    const { html, status_name: statusName, message, error } = await response.json();

    if (response.ok) {
      const htmlTarget = document.querySelector("#submission-tab");
      this.placeHTMLResponse(html, htmlTarget);
      alertsTarget.classList.add("alert-success", "p-4");
      this.placeHTMLResponse(message, alertsTarget);

      location.reload();
    } else {
      alertsTarget.classList.add("alert-danger", "p-4");
      this.placeHTMLResponse(error, alertsTarget);
    }

    input_mask.init();
    datetimepicker.set_picker();
    dashboard.init();
    timestampsConverter.init();
  }

  checkboxSelectedAction(event) {
    if (event.target.checked) {
      event.target.parentElement.classList.add("selected");
    } else {
      event.target.parentElement.classList.remove("selected");
    }
  }

  async renderDocumentUploadModal(event) {
    const response = await this.sendRequest(
      this.newDashboardSubmissionGroupDocumentPathValue
    );

    if (response.ok) {
      const { html } = await response.json();
      const target = document.querySelector("#modal_popup");
      this.placeHTMLResponse(html, target);

      selectpicker.init();
      this.toggleModal("uploadDocumentsModal");
    }
  }

  async renderScheduleUploadModal(event) {
    const response = await this.sendRequest(
      this.newDashboardSubmissionGroupScheduleTemplatePathValue
    );

    if (response.ok) {
      const { html } = await response.json();
      const target = document.querySelector("#modal_popup");
      this.placeHTMLResponse(html, target);

      selectpicker.init();
      this.toggleModal("uploadSceduleTemplatesModal");
    }
  }

  async renderFetchApplicationsModal(event) {
    const response = await this.sendRequest(
      this.fetchApplicationsDashboardSubmissionGroupPathValue
    );

    if (response.ok) {
      const { html } = await response.json();
      const target = document.querySelector("#modal_popup");
      this.placeHTMLResponse(html, target);

      selectpicker.init();
      this.toggleModal("addApplicationsModal");
    }
  }

  async syncSubmissionGroups(event) {
    const alertsTarget = document.getElementById("submission-group-alerts");
    alertsTarget.classList.add("d-none");

    const response = await this.sendRequest(
      this.syncSubmissionGroupsDashboardSubmissionGroupPathValue,
      "PATCH"
    );
    alertsTarget.classList.remove("d-none");

    if (response.ok) {
      const { html } = await response.json();
      alertsTarget.classList.add("alert-success", "p-4");
      this.placeHTMLResponse(html, alertsTarget);
    } else {
      alertsTarget.classList.add("alert-danger", "p-4");
      this.placeHTMLResponse(
        "Something went wrong! Please try again!",
        alertsTarget
      );
    }
  }

  async validateDocumentAddition(event) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const atLeastOneChecked = false;

    checkboxes.forEach(function (checkbox) {
      if (checkbox.checked) {
        atLeastOneChecked = true;
        return;
      }
    });

    if (!atLeastOneChecked) {
      event.preventDefault();
      alert("Please select at least one document.");
    }
  }

  async toggleReadButton(event) {
    event.preventDefault();

    const content = event.target.parentElement.children[0];
    const button = event.target;

    if (content.classList.contains("expanded")) {
      content.classList.remove("expanded");
      button.innerText = "Read More";
    } else {
      content.classList.add("expanded");
      button.innerText = "Read Less";
    }
  }

  async toggleEmailContent(event) {
    if (event.target.checked) {
      document.getElementById('linked-companies').classList.remove("show");
      document.getElementById('linked-companies').classList.add("hide");
      document.getElementById('markets').classList.remove("hide");
      document.getElementById('markets').classList.add("show");
    } else {
      document.getElementById('linked-companies').classList.remove("hide");
      document.getElementById('linked-companies').classList.add("show");
      document.getElementById('markets').classList.remove("show");
      document.getElementById('markets').classList.add("hide");
    }
  }
}
