import { Controller } from "@hotwired/stimulus";
import {
  enableButton,
  disableButton,
  toggle,
  clearNodeContent,
  setNodeContent,
  clearObjectFields,
} from "../shared";

export default class extends Controller {
  static targets = [
    "sendCollaborationInviteModal",
    "insuranceApplicationUuid",
    "templateUrlName",
    "sectionKey",
    "authToken",
    "email",
    "firstName",
    "lastName",
    "errorNode",
    "successNode"
  ];
  static values = { email: String };

  async fetchClientData() {
    const _email = this.emailTarget.value;
    const url = new URL(window.location.origin + this.emailTarget.dataset.url);
    url.searchParams.append("email", _email);

    const response = await fetch(url, {
      headers: {
        "X-Rquested-With": "XMLHttpRequest",
        Accept: "application/json",
      },
    });

    if (response.ok) {
      const client = await response.json();

      if (client.id !== null) {
        this.firstNameTarget.value = client.first_name;
        this.lastNameTarget.value = client.last_name;
      }
    }
  }

  async inviteInsuredCollaborator(event) {
    event.preventDefault();
    const sectionKey = this.sectionKeyTarget.value;
    const shareCountSpanElement = document.getElementById(sectionKey).querySelector('.section-share-count');

    const url = `/dashboard/templates/${this.templateUrlNameTarget.value}/application_submissions/${this.insuranceApplicationUuidTarget.value}/invite_insured_collaborator?auth_token=${this.authTokenTarget.value}`;
    const form = event.target;
    const formData = new FormData(form);
    formData.append("section_key", sectionKey);
    this.readyForResponse();

    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      setNodeContent(this.errorNodeTarget, message);
    } else {
      clearObjectFields();
      setNodeContent(
        this.successNodeTarget,
        "Collaboration invite sent successfully!"
      );
      this.populateCollaborationHistory();
      this.incrementSectionShareCountValue(shareCountSpanElement);
    }

    this.enableAllButtons();
  }

  async populateCollaborationHistory() {
    const insuranceApplicationId = this.insuranceApplicationUuidTarget.value;
    const templateUrlName = this.templateUrlNameTarget.value;
    const sectionKey = this.sectionKeyTarget.value;
    const authToken = this.authTokenTarget.value;

    const response = await fetch(
      `/dashboard/templates/${templateUrlName}/application_submissions/${insuranceApplicationId}/collaboration_history?section_key=${sectionKey}&auth_token=${authToken}`,
      { method: "GET" }
    );

    const { html } = await response.json();
    document.getElementById("collaboration-history").innerHTML = html;
  }

  async reinviteInsuredCollaborator(event) {
    const insuredCollaboratorSectionId = event.target.dataset.id;
    const authToken = event.target.dataset.authToken;
    const url = `/dashboard/templates/${this.templateUrlNameTarget.value}/application_submissions/${this.insuranceApplicationUuidTarget.value}/reinvite_insured_collaborator?insured_collaborator_section_id=${insuredCollaboratorSectionId}&auth_token=${authToken}`;
    this.readyForResponse();

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      setNodeContent(this.errorNodeTarget, message);
    } else {
      setNodeContent(
        this.successNodeTarget,
        "Collaboration invite sent successfully!"
      );
    }

    this.enableAllButtons();
  }

  async updateCollaborationAccess(event) {
    const insuredCollaboratorSectionId = event.target.dataset.id;
    const activeFlagValue = JSON.parse(event.target.dataset.value);
    const authToken = event.target.dataset.authToken;

    const url = `/dashboard/templates/${this.templateUrlNameTarget.value}/application_submissions/${this.insuranceApplicationUuidTarget.value}/update_collaboration_access?insured_collaborator_section_id=${insuredCollaboratorSectionId}&auth_token=${authToken}`;
    this.readyForResponse();

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": $.rails.csrfToken(),
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      setNodeContent(this.errorNodeTarget, message);
    } else {
      setNodeContent(
        this.successNodeTarget,
        `Section ${activeFlagValue === true ? 'disabled' : 'enabled'} successfully!`
      );

      event.target.dataset.value = !activeFlagValue;
      event.target.innerHTML = activeFlagValue === true ? 'Enable' : 'Disable';
    }

    this.enableAllButtons();
  }

  readyForResponse() {
    clearNodeContent(this.errorNodeTarget);
    clearNodeContent(this.successNodeTarget);
    this.disableAllButtons();
  }

  disableAllButtons = () => {
    const buttons = document.querySelectorAll('.insured_collaborator_modal_button');

    buttons.forEach(button => {
      disableButton(button);
    });
  };

  enableAllButtons(){
    const buttons = document.querySelectorAll('.insured_collaborator_modal_button');

    buttons.forEach(button => {
      enableButton(button);
    });
  }

  closeModal() {
    toggle(this.sendCollaborationInviteModalTarget);
  }

  incrementSectionShareCountValue(shareCountSpanElement){
    const shareCountValue = parseInt(shareCountSpanElement.innerHTML)

    if (isNaN(shareCountValue))
      shareCountSpanElement.innerHTML = 1
    else
      shareCountSpanElement.innerHTML = shareCountValue + 1
  }
}
