import {create_element} from "../../themes/manipulate/create";
import {
  decode_uri_component,
  get_data_from_local_storage,
  get_session_cookie,
  parse_to_json,
} from "../../dashboard/javascript/utils";
import AesAuthentication from "../../../services/aes_authentication";

export const createSectionTabs = (
  current_section_key,
  sectionsShareCountValue = {}
) => {
  let sections = parse_to_json(get_data_from_local_storage("sections"));
  let wizard_sections = parse_to_json(
    get_data_from_local_storage("wizard_sections")
  );

  placeSections(
    sections,
    wizard_sections,
    current_section_key,
    sectionsShareCountValue
  );
};

export const filterWizardJson = (wizard) => {
  let sectionsArray = [];

  wizard.forEach((item) => {
    sectionsArray.push(item.key || item.component.key);
  });

  return sectionsArray;
};

export const filterFormJson = (form_json) => {
  let sectionsArray = [];

  form_json.forEach((item) => {
    if (item.type === "panel" && !item.hasOwnProperty("parent")) {
      sectionsArray.push({
        key: item["key"],
        title: item["title"],
      });
    }
  });

  return sectionsArray;
};

export const placeSections = (
  section_keys,
  wizard_keys,
  current_section_key,
  sectionsShareCountValue = {}
) => {
  // for storing sections HTMLNode List
  let sections = [];

  // for section which is currently active
  let active_section = null;

  // main sections container and to empty it
  let sections_container = document.getElementById("sections");
  sections_container.innerHTML = "";

  section_keys.map((section, index) => {
    let current_section = createSection(
      section,
      wizard_keys.indexOf(section.key),
      current_section_key,
      index
    );

    if (!section.key.toLowerCase().includes("signature"))
      bindShareModalClick(
        current_section,
        wizard_keys.indexOf(section.key) >= 0,
        sectionsShareCountValue
      );

    if (section?.key === current_section_key) active_section = current_section;

    sections.push(current_section);
  });

  sections.map((section) => {
    sections_container.appendChild(section);
  });

  const itemPosition = active_section.offsetTop;
  const containerScrollTop = sections_container.scrollTop;
  const containerHeight = sections_container.clientHeight;

  if (itemPosition > containerScrollTop) {
    // If the selected item is above the visible area, scroll up to it
    sections_container.scrollTop =
      itemPosition - active_section.clientHeight * 7;
  } else if (
    itemPosition + active_section.clientHeight >
    containerScrollTop + containerHeight
  ) {
    // If the selected item is below the visible area, scroll down to it
    sections_container.scrollTop =
      itemPosition - containerHeight + active_section.clientHeight;
  }
};

export const createSection = (section, page, active_section, index) => {
  let card = create_element("div", {
    class: "px-3 d-flex side-item alt-align-center justify-between py-3 gap-25",
    'data-page': page
  });

  if (section.key !== "additional-information")
    card.setAttribute("id", section.key);

  const plainTitle = section.title?.replace(/<\/?[^>]+(>|$)/g, "");
  let heading = create_element(
    "span",
    {class: "font-family-poppins"},
    plainTitle
  );

  card.style.borderRadius = "var(--border-radius)";

  if (section?.key === active_section) {
    card.classList.add("active");
    heading.classList.add("font-weight-medium");
  }

  if (page === -1 && section?.key !== "additional-information") {
    heading.classList.add("disabled");
  } else {
    card.setAttribute('data-enabled', 'true');
    card.classList.add('cursor-pointer');
  }

  card.appendChild(heading);

  return card;
};

export const capitalizeText = (text) => {
  if (text) {
    text = text.toLowerCase();
    const words = text.split(/(\s+|[^a-zA-Z0-9])/);

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word !== "") {
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
    }

    return words.join("");
  } else {
    return "";
  }
};

export const bindShareModalClick = async (
  sectionDiv,
  enabled = true,
  sectionsShareCountValue = {}
) => {
  if (!enabled) return;

  const shareButtondiv = create_element("div", {
    class: "gap-10 alt-align-center fs-xs font-weight-medium share-button",
    style:
      "font-weight: 500;cursor: pointer;border-radius: var(--border-radius-5);padding: 0;margin: 0;",
  });
  sectionDiv.appendChild(shareButtondiv);

  encryptSectionData(sectionDiv);

  const shareCount = create_element("span", {class: 'section-share-count', style: "padding-left: 6px;"});
  sectionsShareCountValue[sectionDiv.id] > 0
    ? (shareCount.textContent = sectionsShareCountValue[sectionDiv.id])
    : shareCount.appendChild(create_element("i", {class: "dc-plus"}));
  shareButtondiv.appendChild(shareCount);

  const separator = create_element("span", {
    style: "margin: 0; height: 2rem;",
  });
  shareButtondiv.appendChild(separator);

  const icon = create_element("i", {
    class: "dc-share-nodes",
    style: "padding-right: 6px;",
  });
  shareButtondiv.appendChild(icon);

  const openModal = async (event) => {
    event.stopPropagation();

    if (!sectionDiv.dataset.key) return

    const templateUrlName = document.getElementById("template_url_name").value;
    const insuranceApplicationId = document.getElementById(
      "insurance_application_uuid"
    ).value;

    const authToken = document.getElementById(
      "auth_token"
    ).value;

    const response = await fetch(
      `/dashboard/templates/${templateUrlName}/application_submissions/${insuranceApplicationId}/section_collaboration?section_key=${sectionDiv.id}&encrypted_data=${sectionDiv.dataset.key}&iv=${sectionDiv.dataset.iv}&auth_token=${authToken}`,
      {
        method: "GET",
        headers: {
          "X-CSRF-Token": $.rails.csrfToken(),
          Accept: "application/json",
        },
      }
    );

    if (response.ok) {
      const {html} = await response.json();
      document.getElementById("sendCollaborationInviteModal").innerHTML = html;
      encryptSectionData(sectionDiv);
    } else {
      location.reload();
    }
  }

  shareButtondiv.addEventListener("click", openModal);
};

export const encryptSectionData = async (element) => {
  const aesAuthentication = new AesAuthentication();

  const aesResponse = await aesAuthentication.authenticate({ key: `section-id-${element.id}`, values: { key: `${element.id}`, section_title: `${element.innerText}` }});
  element.setAttribute('data-key', aesResponse.encryptedData);
  element.setAttribute('data-iv', aesResponse.iv);
}
