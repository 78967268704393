import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  filterEmployees() {
    const searchText = this.inputTarget.value.toLowerCase().trim();
    const employeeRows = document.querySelectorAll(".employee-row");

    employeeRows.forEach(row => {
      const employeeName = row.querySelector("td:first-child").textContent.toLowerCase();

      if (employeeName.includes(searchText)) {
        row.style.display = "table-row";
      } else {
        row.style.display = "none";
      }
    });
  }
}
